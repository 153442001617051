const nodeEnv = process.env.NODE_ENV;
const PROD_EU = 'prod-eu';
const PROD_US = 'production';

export interface AlgoliaIndex {
  DEFAULT: string;
  LAST_EDITED_DATE_ASC: string;
  LAST_EDITED_DATE_DESC: string;
  NAME_ASC: string;
  NAME_DESC: string;
  TOTAL_VIEWS_ASC: string;
  TOTAL_VIEWS_DESC: string;
}

export enum DefaultAWSRegion {
  US = 'us-west-2',
  EU = 'eu-central-1',
}

export interface IntegrationOAuthConfig {
  clientID: string;
  redirectURI: string;
}

const getClientSentryDsn = (environment: any, namespace: any, nodeEnv: any) => {
  if (nodeEnv === 'test') {
    return '';
  }

  switch (environment) {
    case PROD_US:
      return 'https://f7003d3a8d52453a834e096cb203501c@o13027.ingest.sentry.io/78869';
    case 'staging':
      if (namespace) {
        // If namespace is populated this is an ephemeral staging deployment
        return 'https://2e3f9b78326345509578b7affcf289f9@o13027.ingest.sentry.io/5337167';
      } else {
        return 'https://df194ee9c4db4a8ca1e8b943a11d2bbd@o13027.ingest.sentry.io/78868';
      }
    case 'development':
      return 'https://b0762dd934e841eebea2708e3858842b@o13027.ingest.sentry.io/74583';
    case PROD_EU:
      return 'https://cd28837ed48646259c359db7e45d242c@o13027.ingest.sentry.io/5622198';
    default:
      return '';
  }
};

const getAmplitudeSdkGaiaKey = (environment: any, nodeEnv: any) => {
  if (nodeEnv === 'test') {
    return '';
  } else if (nodeEnv === 'development' || nodeEnv === 'perf') {
    // local development
    return 'ba4b96b3b6cde1d722e60aac370093c7';
  }

  switch (environment) {
    case PROD_US:
      return 'e5a2c9bdffe949f7da77e6b481e118fa';
    case 'staging':
      return 'a3c23da3b63f5b6bed50b0b6f252567f';
    case 'development':
      return 'ce58b28cace35f7df0eb241b0cd72044';
    case PROD_EU:
      return '4ccbf62d3b2a2b840cdc385c724a68ca';
    default:
      return '';
  }
};

const getAmplitudeSdkEnvConfig = (
  environment: any,
  nodeEnv: any,
): { key: string; apiEndpoint: string } | null => {
  if (nodeEnv === 'test') {
    return null;
  }

  switch (environment) {
    // EU
    case PROD_EU:
      return {
        key: 'c4918f03c986765e0d7b495199f86e48',
        apiEndpoint: 'api.eu.amplitude.com',
      };
    default:
      return null;
  }
};

const getAmplitudeSdkReactKey = (environment: any, nodeEnv: any) => {
  switch (nodeEnv) {
    case 'test':
      return '';
    case 'development':
    case 'perf':
      return '9cd8e0be78891e2ba4288eaa76435fd3';
  }

  switch (environment) {
    case PROD_US:
      return '6d22839622d0fe3037fc196b33c90578';
    case PROD_EU:
      return '2cdf48437dfd8a098b2c7f93da0b8187';
    default:
      return '';
  }
};

const getAmplitudeAppTeamInfrastructureKey = (
  environment: any,
  nodeEnv: any,
) => {
  switch (nodeEnv) {
    case 'test':
      return '';
    case 'development':
    case 'perf':
      return 'bb4c3eafb5ccb0f0e73e32dd5c63ecda';
  }

  switch (environment) {
    case PROD_US:
      return 'fb0efa3ca84d3fa90b9722e73a65c730';
    case PROD_EU:
      return '93be69dba20cd60ddd9d3a48e176ceac';
    default:
      return '';
  }
};

const getSlackClientId = (deployment: any, environment: any, nodeEnv: any) => {
  if (nodeEnv === 'test') {
    return '';
  }

  if (environment === PROD_EU) {
    return '2359418194.2175895532818'; // prod eu slack app
  }

  switch (deployment) {
    case 'prod':
      return '2359418194.79837860256';
    case 'stag':
    case 'dev':
    default:
      return '2359418194.77355742816';
  }
};

const getSsoCaptchaSiteKey = () => {
  return '6LeppFYUAAAAALuOzjDr3mbJjUhys2Bj3A6_qMk9';
};

const getFacebookAppId = () => {
  return '391178095022400';
};

const getNATGateways = (environment: any) => {
  if (environment === PROD_EU) {
    return ['3.124.22.25', '18.157.59.125', '18.192.47.195'];
  }

  return ['52.33.3.219', '52.27.10.221', '35.162.216.242'];
};

const getDefaultAWSRegion = (environment: any): DefaultAWSRegion => {
  switch (environment) {
    case PROD_EU:
      return DefaultAWSRegion.EU;
    case PROD_US:
    default:
      return DefaultAWSRegion.US;
  }
};

const getVpce = (environment: any) => {
  if (environment === PROD_EU) {
    return 'vpce-0ea17096d8975a789';
  }

  return 'vpce-88b275e1';
};

const getRootPrincipal = (environment: any) => {
  // EU
  if (environment === PROD_EU) {
    return 'arn:aws:iam::202493300829:root';
  }

  return 'arn:aws:iam::358203115967:root';
};

const getSkylabClientKey = (deployment: any, nodeEnv: any) => {
  if (nodeEnv === 'test') {
    return '';
  }
  switch (deployment) {
    case 'prod':
      return 'client-TOagPM8vfADC9LhZwsUjF04G6g6QKGnt';
    case 'stag':
      return 'client-75jOyETRMUlbhbwa1GcuXRwxycj7JL4Q';
    case 'dev':
    default:
      return 'client-Vne8KdiZLCS9P6jeTKVAnitvKdV1irtV';
  }
};

const getAmplitudeOrgId = (environment: any) => {
  if (environment === PROD_EU) {
    return '100000002';
  } else {
    return '36958';
  }
};

// NOTE(Devin): I will need to duplicate indices in both eu prod and prod. Need to get access from Kahn to prod eu
const getAlgoliaIndices = (environment: any): AlgoliaIndex => {
  if (environment === PROD_EU) {
    return {
      DEFAULT: 'amplitude_prod_eu',
      LAST_EDITED_DATE_ASC: 'amplitude_prod_eu_sort_by_last_modified_asc',
      LAST_EDITED_DATE_DESC: 'amplitude_prod_eu_sort_by_last_modified',
      NAME_ASC: 'amplitude_prod_eu_sort_by_name_asc',
      NAME_DESC: 'amplitude_prod_eu_sort_by_name_desc',
      TOTAL_VIEWS_ASC: 'amplitude_prod_eu_sort_by_total_views_asc',
      TOTAL_VIEWS_DESC: 'amplitude_prod_eu_sort_by_total_views',
    };
  } else {
    return {
      DEFAULT: 'amplitude_production',
      LAST_EDITED_DATE_ASC: 'amplitude_production_sort_by_last_modified_asc',
      LAST_EDITED_DATE_DESC: 'amplitude_production_sort_by_last_modified',
      NAME_ASC: 'amplitude_production_sort_by_name_asc',
      NAME_DESC: 'amplitude_production_sort_by_name_desc',
      TOTAL_VIEWS_ASC: 'amplitude_production_sort_by_total_views_asc',
      TOTAL_VIEWS_DESC: 'amplitude_production_sort_by_total_views',
    };
  }
};

const getAlgoliaAppId = (environment: any) => {
  if (environment === PROD_EU) {
    return 'QKAXZ4SEIN';
  } else {
    return 'MRQCO3H43U';
  }
};

const getAgGridLicense = (deployment: any) => {
  if (deployment === 'prod') {
    return 'CompanyName=Amplitude,LicensedGroup=datamonster,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=5,LicensedProductionInstancesCount=1,AssetReference=AG-025198,ExpiryDate=4_February_2023_[v2]_MTY3NTQ2ODgwMDAwMA==6d58034b96be7d7f01cb8eb19273f83a';
  }

  return '';
};

const getUserAvatarOrigin = (environment: any) => {
  // NOTE(willh): User avatars are currently stored in the same bucket as all other static web
  // assets. But not all assets are split by environment e.g. the built js files are always hosted
  // from the US CloudFront (static.amplitude.com).
  if (environment === PROD_EU) {
    return 'https://static.eu.amplitude.com';
  } else {
    return 'https://static.amplitude.com';
  }
};

export const getIntercomClientId = (
  environment: string | undefined,
): string => {
  if (environment === PROD_EU) {
    return 'aafdfbf9-60a1-40dc-bd65-aaa90ff19a7b';
  } else {
    return '30826bf7-cfab-4c24-ae77-b5ddd877a15e';
  }
};

export const getHubSpotClientId = (environment: string | undefined): string => {
  if (environment === PROD_EU) {
    return '70b91d47-9fdb-4803-a542-845c222c31dd';
  } else {
    return 'ebe8696e-b615-461d-897d-dbba95ad1026';
  }
};

export const getHubSpotRedirectURI = (
  environment: string | undefined,
): string => {
  if (environment === PROD_EU) {
    return 'https://analytics.eu.amplitude.com/config/hubspot/auth';
  } else {
    return 'https://analytics.amplitude.com/config/hubspot/auth';
  }
};

export const getMailchimpConfig = (
  environment: string | undefined,
  nodeEnv: string | undefined,
): IntegrationOAuthConfig => {
  switch (nodeEnv) {
    case 'development':
      // local
      return {
        clientID: '631944772372',
        redirectURI: 'http://127.0.0.1:3000/config/mailchimp/auth',
      };
    case 'perf':
    case 'test':
      return {
        clientID: '',
        redirectURI: '',
      };
  }

  switch (environment) {
    case PROD_US:
      return {
        clientID: '533899365394',
        redirectURI: 'https://analytics.amplitude.com/config/mailchimp/auth',
      };
    case 'staging':
      return {
        clientID: '998197057866',
        redirectURI:
          'https://analytics.staging.amplitude.com/config/mailchimp/auth',
      };
    case PROD_EU:
      return {
        clientID: '719164686963',
        redirectURI: 'https://analytics.eu.amplitude.com/config/mailchimp/auth',
      };
    default:
      return {
        clientID: '',
        redirectURI: '',
      };
  }
};

export const getQualtricsConfig = (
  environment: string | undefined,
  nodeEnv: string | undefined,
): IntegrationOAuthConfig => {
  switch (nodeEnv) {
    case 'development':
      // local
      return {
        clientID: 'bdf1f132e4273742437fabdcfbbb21df',
        redirectURI: 'https://127.0.0.1:3000/config/integration-oauth/callback',
      };
    case 'perf':
    case 'test':
      return {
        clientID: '',
        redirectURI: '',
      };
  }

  switch (environment) {
    case PROD_US:
      return {
        clientID: 'cc1a6b6bfb112f7dc7fbea9c6fc89ac6',
        redirectURI:
          'https://analytics.amplitude.com/config/integration-oauth/callback',
      };
    case 'staging':
      return {
        clientID: 'c5ca1ec0fa1dc9fde273dc956d5ea656',
        redirectURI:
          'https://analytics.staging.amplitude.com/config/integration-oauth/callback',
      };
    case PROD_EU:
      return {
        clientID: '55864d8a4fbe708236989014b6611e36',
        redirectURI:
          'https://analytics.eu.amplitude.com/config/integration-oauth/callback',
      };
    default:
      return {
        clientID: '',
        redirectURI: '',
      };
  }
};

const getHermesHost = (environment?: string, nodeEnv?: string) => {
  if (nodeEnv === 'development') {
    return 'http://localhost:3007';
  }

  switch (environment) {
    case PROD_US:
      return 'https://notify.amplitude.com';
    case PROD_EU:
      return 'https://notify.eu.amplitude.com';
    case 'staging':
      return 'https://notify.staging.amplitude.com';
    case 'development':
    default:
      return 'https://notify.dev.amplitude.com';
  }
};

const getEventServerURL = (environment: any) => {
  if (environment === PROD_US) {
    return 'https://api.amplitude.com';
  } else if (environment === PROD_EU) {
    return 'https://api.eu.amplitude.com';
  }

  return '';
};

const getHttpApiUrl = (environment: any, nodeEnv: any) => {
  if (environment === PROD_EU) {
    return 'https://api.eu.amplitude.com/2/httpapi';
  }
  if (environment === PROD_US || nodeEnv === PROD_US) {
    return 'https://api.amplitude.com/2/httpapi';
  }

  // A fallback, expecting this to not cause any issues, but please do change back to '' if necessary
  return 'https://api.amplitude.com/2/httpapi';
};

const getMailchimpWebhookURL = (environment: any, nodeEnv: any) => {
  switch (nodeEnv) {
    case 'development':
      // local
      return 'http://localhost:3000/config/mailchimp/webhook';
    case 'perf':
    case 'test':
      return '';
  }

  switch (environment) {
    case PROD_US:
      return 'https://analytics.amplitude.com/config/mailchimp/webhook';
    case 'staging':
      return 'https://analytics.staging.amplitude.com/config/mailchimp/webhook';
    case PROD_EU:
      return 'https://analytics.eu.amplitude.com/config/mailchimp/webhook';
    default:
      return '';
  }
};

const getStargateHost = (environment?: string, nodeEnv?: string) => {
  if (nodeEnv === 'development') {
    return 'http://localhost:3005';
  }

  switch (environment) {
    case PROD_US:
      return 'https://data.amplitude.com';
    case PROD_EU:
      return 'https://data.eu.amplitude.com';
    case 'staging':
      return 'https://data.staging.amplitude.com';
    case 'development':
    default:
      return 'https://data.dev.amplitude.com';
  }
};

export default {
  amplitudeOrgId: getAmplitudeOrgId(window.__ENVIRONMENT__),
  baseUrl: 'https://analytics.amplitude.com',
  keys: {
    AG_GRID_LICENSE_KEY: getAgGridLicense(window.__DEPLOYMENT__),
    AMPLITUDE_SDK_GAIA_KEY: getAmplitudeSdkGaiaKey(
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    AMPLITUDE_SDK_REACT_KEY: getAmplitudeSdkReactKey(
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    AMPLITUDE_APP_TEAM_INFRASTRUCTURE_KEY: getAmplitudeAppTeamInfrastructureKey(
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    FACEBOOK_APP_ID: getFacebookAppId(),
    GOOGLE_ADS_CLIENT_ID:
      '94349717549-nqgopuknlcdrfsn9v74m8ve8818j1jrf.apps.googleusercontent.com',
    SENTRY_DSN: getClientSentryDsn(
      window.__ENVIRONMENT__,
      window.__NAMESPACE__,
      nodeEnv,
    ),
    SKYLAB_CLIENT_KEY: getSkylabClientKey(window.__DEPLOYMENT__, nodeEnv),
    SLACK_CLIENT_ID: getSlackClientId(
      window.__DEPLOYMENT__,
      window.__ENVIRONMENT__,
      nodeEnv,
    ),
    SSO_CAPTCHA_SITE_KEY: getSsoCaptchaSiteKey(),
    RECAPTCHA_V3_SITE_KEY: '6LcPvI4UAAAAAG_aZC6wXdHsgWMBN9V6BVdqHS4F',
    INTERCOM_CLIENT_ID: getIntercomClientId(window.__ENVIRONMENT__),
    HUBSPOT_CLIENT_ID: getHubSpotClientId(window.__ENVIRONMENT__),
    HUBSPOT_REDIRECT_URI: getHubSpotRedirectURI(window.__ENVIRONMENT__),
    HUBSPOT_SCOPE:
      'oauth crm.lists.read crm.lists.write crm.objects.contacts.read crm.objects.contacts.write integration-sync',
    DATADOG_APP_ID: 'fb8a9eb4-42ae-4f15-8773-2bbffdab6e6d',
    DATADOG_CLIENT_TOKEN: 'pub0b062d82219f14caa0bdbbd249ae08b5',
    MAILCHIMP_CONFIG: getMailchimpConfig(window.__ENVIRONMENT__, nodeEnv),
    QUALTRICS_CONFIG: getQualtricsConfig(window.__ENVIRONMENT__, nodeEnv),
  },
  MAILCHIMP_WEBHOOK_URL: getMailchimpWebhookURL(
    window.__ENVIRONMENT__,
    nodeEnv,
  ),
  AMPLITUDE_SDK_ENV_CONFIG: getAmplitudeSdkEnvConfig(
    window.__ENVIRONMENT__,
    nodeEnv,
  ),
  ALGOLIA_INDICES: getAlgoliaIndices(window.__ENVIRONMENT__),
  ALGOLIA_APP_ID: getAlgoliaAppId(window.__ENVIRONMENT__),
  safeRedirectProtocol: window.__DEPLOYMENT__ === 'local' ? 'http:' : 'https:',
  NAT_GATEWAYS: getNATGateways(window.__ENVIRONMENT__),
  PRINCIPAL_AWS: getRootPrincipal(window.__ENVIRONMENT__),
  DEFAULT_AWS_REGION: getDefaultAWSRegion(window.__ENVIRONMENT__),
  VPCE: getVpce(window.__ENVIRONMENT__),
  USER_AVATAR_ORIGIN: getUserAvatarOrigin(window.__ENVIRONMENT__),
  EVENT_SERVER_URL: getEventServerURL(window.__ENVIRONMENT__),
  HTTP_API_URL: getHttpApiUrl(window.__ENVIRONMENT__, nodeEnv),
  ENV: window.__ENVIRONMENT__ ?? process.env.NODE_ENV,
  VERSION: process.env.REACT_APP_VERSION,
  HERMES_HOST: getHermesHost(window.__ENVIRONMENT__, nodeEnv),
  STARGATE_HOST_URL: getStargateHost(window.__ENVIRONMENT__, nodeEnv),
};
