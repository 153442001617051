export const isDev = (): boolean => {
  return process.env.NODE_ENV === 'development';
};

export const isStaging = (): boolean => {
  return window.__DEPLOYMENT__ === 'stag';
};

export const isProd = (): boolean => {
  return window.__DEPLOYMENT__ === 'prod';
};
