import { datadogRum } from '@datadog/browser-rum';
import WebFontLoader from 'webfontloader';

import config from './Config';

import '~/index.css';
import './styles/core.scss';

// We want to run this asap because we want it to start timing for us
if (config.DATADOG_APP_ID && config.DATADOG_CLIENT_TOKEN) {
  datadogRum.init({
    applicationId: config.DATADOG_APP_ID,
    clientToken: config.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'stargate',
    env: process.env.NODE_ENV,
    version: process.env.REACT_APP_VERSION,
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [
      config.THUNDER_HOST,
      config.DASH_HOST,
      config.ANALYTICS_HOST,
      config.STARGATE_HOST,
      process.env.REACT_APP_STARGATE_SERVER_HOST || '',
    ],
  });
  datadogRum.startSessionReplayRecording();
}

const [, orgUrl, ,] = window.location.pathname.split('/');

const publicPages = ['public-doc', 'cli'];

if (publicPages.some((path) => path === orgUrl)) {
  import('./apps/PublicApp');
} else {
  import('./apps/App');
}

WebFontLoader.load({
  custom: {
    families: ['FontAwesome'],
  },
});
