import LightningConfig from 'lightning/src/config';
import {
  getHubSpotClientId,
  getHubSpotRedirectURI,
  getIntercomClientId,
  getMailchimpConfig,
  getQualtricsConfig,
  IntegrationOAuthConfig,
} from 'lightning/src/config/_base';

const nodeEnv = process.env.NODE_ENV;

interface IConfig {
  DEFAULT_AVATAR_URL: string;
  API_URL: string;
  IMPORT_URL: string;
  EXPORT_URL: string;
  PUBLIC_API_URL: string;
  ANONYMOUS_API_URL: string;
  PUSHER_APP_KEY: string;
  DEVELOPMENT_MODE: boolean;
  LOCALHOST_MODE: boolean;
  DASH_HOST: string;
  THUNDER_HOST: string;
  ANALYTICS_HOST: string;
  STARGATE_HOST: string;
  SENTRY_DSN: string;
  LOGOUT_URL: string;
  DATADOG_APP_ID: string;
  DATADOG_CLIENT_TOKEN: string;
  JIRA_CLIENT_ID: string;
  JIRA_CALLBACK_URI: string;
  USERFLOW_CLIENT_TOKEN: string;
  SKYLAB_CLIENT_KEY: string;
  INTERCOM_CLIENT_ID: string;
  HUBSPOT_CLIENT_ID: string;
  HUBSPOT_REDIRECT_URI: string;
  HUBSPOT_SCOPE: string;
  MAILCHIMP_CONFIG: IntegrationOAuthConfig;
  STARGATE_IMPORT_TEMPLATE_URL: string; // the new format (After 2022-03-21)
  ITERATIVELY_IMPORT_TEMPLATE_URL: string; // the old format (Before 2022-03-21)
  QUALTRICS_CONFIG: IntegrationOAuthConfig;
}

const getClientSentryDsn = (
  environment?: 'prod-eu' | 'staging' | 'development' | 'production',
  namespace?: string,
  nodeEnv?: string,
) => {
  if (nodeEnv === 'test') {
    return '';
  }

  switch (environment) {
    // US
    case 'production':
      return 'https://f24a040c352f45af811e277a92372da6@o13027.ingest.sentry.io/5974455';
    case 'staging':
      if (namespace) {
        // If namespace is populated this is an ephemeral staging deployment
        return 'https://c46a310b5d534c408a3b42a0a43169f5@o13027.ingest.sentry.io/5974453';
      } else {
        return 'https://8a6c7d4aa3c94627a7a456091e56c16a@o13027.ingest.sentry.io/5974456';
      }
    case 'development':
      return 'https://bb12401bfc5a455cafd1e3f438faa202@o13027.ingest.sentry.io/5974452';
    // EU
    case 'prod-eu':
      return 'https://eece4a81c690449896ae719dbe04216d@o13027.ingest.sentry.io/5974454';
    default:
      return '';
  }
};

// TODO: redo this so we're not sending dev config to browsers
const local: IConfig = {
  API_URL: '/graphql',
  IMPORT_URL: '/import',
  EXPORT_URL: '/export',
  PUBLIC_API_URL: '/graphql-public/graphql',
  ANONYMOUS_API_URL: '/graphql-anonymous',
  DEFAULT_AVATAR_URL: 'https://data.staging.amplitude.com/images/user.png',
  PUSHER_APP_KEY: '90d46d2e9a607bc79649',
  DASH_HOST: '',
  THUNDER_HOST: '',
  ANALYTICS_HOST: process.env.REACT_APP_ANALYTICS_HOST || '',
  STARGATE_HOST: process.env.REACT_APP_PUBLIC_BASE_PATH || '',
  DEVELOPMENT_MODE: true,
  LOCALHOST_MODE: true,
  SENTRY_DSN: getClientSentryDsn('development', window.__NAMESPACE__, 'test'),
  LOGOUT_URL: 'http://localhost:3001/logout',
  DATADOG_APP_ID: '6c1c3815-2121-48ea-9331-283bdb686d17',
  DATADOG_CLIENT_TOKEN: 'puba535736d90207aac3b517869f9ab8900',
  JIRA_CLIENT_ID: 'IxiRkddA0MFtj5MBVrOofF66RYHG0B7t',
  JIRA_CALLBACK_URI: `${
    process.env.REACT_APP_STARGATE_SERVER_HOST || ''
  }/integration/jira/authentication`,
  USERFLOW_CLIENT_TOKEN: 'ct_xwegrbo2yjgv3dkyzpmysjve6i',
  SKYLAB_CLIENT_KEY: 'client-VVGQSXrFzlrU4D434cZNEOB4gy08IGVN',
  INTERCOM_CLIENT_ID: getIntercomClientId('development'),
  HUBSPOT_CLIENT_ID: getHubSpotClientId('development'),
  HUBSPOT_REDIRECT_URI: getHubSpotRedirectURI('development'),
  HUBSPOT_SCOPE: LightningConfig.keys.HUBSPOT_SCOPE,
  MAILCHIMP_CONFIG: getMailchimpConfig('', 'development'),
  STARGATE_IMPORT_TEMPLATE_URL: `${process.env.REACT_APP_PUBLIC_BASE_PATH}/static/import-template.csv`,
  ITERATIVELY_IMPORT_TEMPLATE_URL: `${process.env.REACT_APP_PUBLIC_BASE_PATH}/static/iteratively-import-example.cs`,
  QUALTRICS_CONFIG: getQualtricsConfig('', 'development'),
};

const dev: IConfig = {
  API_URL: `${process.env.REACT_APP_STARGATE_SERVER_HOST || ''}/graphql`,
  IMPORT_URL: `${process.env.REACT_APP_STARGATE_SERVER_HOST || ''}/import`,
  EXPORT_URL: `${process.env.REACT_APP_STARGATE_SERVER_HOST || ''}/export`,
  PUBLIC_API_URL: `${
    process.env.REACT_APP_STARGATE_SERVER_HOST || ''
  }/graphql-public/graphql`,
  ANONYMOUS_API_URL: `${
    process.env.REACT_APP_STARGATE_SERVER_HOST || ''
  }/graphql-anonymous`,
  PUSHER_APP_KEY: '90d46d2e9a607bc79649',
  DASH_HOST: process.env.REACT_APP_DASH_HOST || '',
  THUNDER_HOST: process.env.REACT_APP_THUNDER_HOST || '',
  ANALYTICS_HOST: process.env.REACT_APP_ANALYTICS_HOST || '',
  STARGATE_HOST: process.env.REACT_APP_PUBLIC_BASE_PATH || '',
  DEFAULT_AVATAR_URL: 'https://data.staging.amplitude.com/images/user.png',
  DEVELOPMENT_MODE: false,
  LOCALHOST_MODE: false,
  SENTRY_DSN: getClientSentryDsn('development', window.__NAMESPACE__, nodeEnv),
  LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL || '',
  DATADOG_APP_ID: '6c1c3815-2121-48ea-9331-283bdb686d17',
  DATADOG_CLIENT_TOKEN: 'puba535736d90207aac3b517869f9ab8900',
  JIRA_CLIENT_ID: 'IxiRkddA0MFtj5MBVrOofF66RYHG0B7t',
  JIRA_CALLBACK_URI: `${
    process.env.REACT_APP_STARGATE_SERVER_HOST || ''
  }/integration/jira/authentication`,
  USERFLOW_CLIENT_TOKEN: 'ct_xwegrbo2yjgv3dkyzpmysjve6i',
  SKYLAB_CLIENT_KEY: 'client-VVGQSXrFzlrU4D434cZNEOB4gy08IGVN',
  INTERCOM_CLIENT_ID: getIntercomClientId('development'),
  HUBSPOT_CLIENT_ID: getHubSpotClientId('development'),
  HUBSPOT_REDIRECT_URI: getHubSpotRedirectURI('development'),
  HUBSPOT_SCOPE: LightningConfig.keys.HUBSPOT_SCOPE,
  MAILCHIMP_CONFIG: getMailchimpConfig('development', nodeEnv),
  STARGATE_IMPORT_TEMPLATE_URL: `${process.env.REACT_APP_PUBLIC_BASE_PATH}/static/import-template.csv`,
  ITERATIVELY_IMPORT_TEMPLATE_URL: `${process.env.REACT_APP_PUBLIC_BASE_PATH}/static/iteratively-import-example.csv`,
  QUALTRICS_CONFIG: getQualtricsConfig('development', nodeEnv),
};

const staging: IConfig = {
  API_URL: `${process.env.REACT_APP_STARGATE_SERVER_HOST || ''}/graphql`,
  IMPORT_URL: `${process.env.REACT_APP_STARGATE_SERVER_HOST || ''}/import`,
  EXPORT_URL: `${process.env.REACT_APP_STARGATE_SERVER_HOST || ''}/export`,
  PUBLIC_API_URL: `${
    process.env.REACT_APP_STARGATE_SERVER_HOST || ''
  }/graphql-public/graphql`,
  ANONYMOUS_API_URL: `${
    process.env.REACT_APP_STARGATE_SERVER_HOST || ''
  }/graphql-anonymous`,
  PUSHER_APP_KEY: '90d46d2e9a607bc79649',
  DASH_HOST: process.env.REACT_APP_DASH_HOST || '',
  THUNDER_HOST: process.env.REACT_APP_THUNDER_HOST || '',
  ANALYTICS_HOST: process.env.REACT_APP_ANALYTICS_HOST || '',
  STARGATE_HOST: process.env.REACT_APP_PUBLIC_BASE_PATH || '',
  DEFAULT_AVATAR_URL: 'https://data.staging.amplitude.com/images/user.png',
  DEVELOPMENT_MODE: false,
  LOCALHOST_MODE: false,
  SENTRY_DSN: getClientSentryDsn('staging', window.__NAMESPACE__, nodeEnv),
  LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL || '',
  DATADOG_APP_ID: '6c1c3815-2121-48ea-9331-283bdb686d17',
  DATADOG_CLIENT_TOKEN: 'puba535736d90207aac3b517869f9ab8900',
  JIRA_CLIENT_ID: 'gWQAxfpdYVrfDgjKMB6SKPaWkqkR3xbm',
  JIRA_CALLBACK_URI: `${
    process.env.REACT_APP_STARGATE_SERVER_HOST || ''
  }/integration/jira/authentication`,
  USERFLOW_CLIENT_TOKEN: 'ct_xwegrbo2yjgv3dkyzpmysjve6i',
  SKYLAB_CLIENT_KEY: 'client-VVGQSXrFzlrU4D434cZNEOB4gy08IGVN',
  INTERCOM_CLIENT_ID: getIntercomClientId('staging'),
  HUBSPOT_CLIENT_ID: getHubSpotClientId('staging'),
  HUBSPOT_REDIRECT_URI: getHubSpotRedirectURI('staging'),
  HUBSPOT_SCOPE: LightningConfig.keys.HUBSPOT_SCOPE,
  MAILCHIMP_CONFIG: getMailchimpConfig('staging', nodeEnv),
  STARGATE_IMPORT_TEMPLATE_URL: `${process.env.REACT_APP_PUBLIC_BASE_PATH}/static/import-template.csv`,
  ITERATIVELY_IMPORT_TEMPLATE_URL: `${process.env.REACT_APP_PUBLIC_BASE_PATH}/static/iteratively-import-example.csv`,
  QUALTRICS_CONFIG: getQualtricsConfig('staging', nodeEnv),
};

const prod: IConfig = {
  API_URL: `${process.env.REACT_APP_STARGATE_SERVER_HOST || ''}/graphql`,
  IMPORT_URL: `${process.env.REACT_APP_STARGATE_SERVER_HOST || ''}/import`,
  EXPORT_URL: `${process.env.REACT_APP_STARGATE_SERVER_HOST || ''}/export`,
  PUBLIC_API_URL: `${
    process.env.REACT_APP_STARGATE_SERVER_HOST || ''
  }/graphql-public/graphql`,
  ANONYMOUS_API_URL: `${
    process.env.REACT_APP_STARGATE_SERVER_HOST || ''
  }/graphql-anonymous`,
  PUSHER_APP_KEY: '66ab967c462ebf38692d',
  DASH_HOST: process.env.REACT_APP_DASH_HOST || '',
  THUNDER_HOST: process.env.REACT_APP_THUNDER_HOST || '',
  ANALYTICS_HOST: process.env.REACT_APP_ANALYTICS_HOST || '',
  STARGATE_HOST: process.env.REACT_APP_PUBLIC_BASE_PATH || '',
  DEFAULT_AVATAR_URL: 'https://data.amplitude.com/images/user.png',
  DEVELOPMENT_MODE: false,
  LOCALHOST_MODE: false,
  SENTRY_DSN: getClientSentryDsn('production', window.__NAMESPACE__, nodeEnv),
  LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL || '',
  DATADOG_APP_ID: '6c1c3815-2121-48ea-9331-283bdb686d17',
  DATADOG_CLIENT_TOKEN: 'puba535736d90207aac3b517869f9ab8900',
  JIRA_CLIENT_ID: 'Z7kml76XujbAdb6BekIUYmPMzMrPZqAD',
  JIRA_CALLBACK_URI: `${
    process.env.REACT_APP_STARGATE_SERVER_HOST || ''
  }/integration/jira/authentication`,
  USERFLOW_CLIENT_TOKEN: 'ct_hwkn3nviz5gjnb53iixkwhmc2q',
  SKYLAB_CLIENT_KEY: 'client-aQZ3Sb91vDKZNfenQcRGJJ6At1jn1wCO',
  INTERCOM_CLIENT_ID: getIntercomClientId('production'),
  HUBSPOT_CLIENT_ID: getHubSpotClientId('production'),
  HUBSPOT_REDIRECT_URI: getHubSpotRedirectURI('production'),
  HUBSPOT_SCOPE: LightningConfig.keys.HUBSPOT_SCOPE,
  MAILCHIMP_CONFIG: getMailchimpConfig('production', nodeEnv),
  STARGATE_IMPORT_TEMPLATE_URL: `${process.env.REACT_APP_PUBLIC_BASE_PATH}/static/import-template.csv`,
  ITERATIVELY_IMPORT_TEMPLATE_URL: `${process.env.REACT_APP_PUBLIC_BASE_PATH}/static/iteratively-import-example.csv`,
  QUALTRICS_CONFIG: getQualtricsConfig('production', nodeEnv),
};

const getConfig = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return prod;
  }

  if (process.env.REACT_APP_ENV === 'development') {
    return dev;
  }

  if (process.env.REACT_APP_ENV === 'staging') {
    return staging;
  }

  return local;
};

export default getConfig();
